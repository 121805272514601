export const PHONE_MASK = '+\\3\\7\\4\\ (99) 999 999'
export const PAST_BUG_PHONE = '+374 (+374'
export const PAST_BUG_8_PHONE = '+374 (8'

export const PHONE_TITLE = '+374'

export const PHONE_MATCHES_REG =
  /\+374\s\((10|11|12|33|41|43|44|46|47|55|77|91|93|94|95|96|97|98|99)\)\s[0-9]{3}\s[0-9]{3}/
export const PHONE_MATCHES_ERR =
  'Проверьте код мобильного оператора!\n Доступные коды операторов: \n 10, 11, 12, 33, 41, 43, 44, 46, 47, 55, 77, 91, 93, 94, 95, 96, 97, 98, 99 \n Пример: +374 (XX) XXX XXX'
