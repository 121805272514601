export const isScreenWidth = (width: number) => {
  return window.innerWidth < width
}

export function getRandomObjects(array: any[], count: number) {
  const shuffled = array.sort(() => 0.5 - Math.random())
  return shuffled.slice(0, count)
}

export function someObjValuesTrue(obj: any) {
  for (let key in obj) {
    if (obj[key]) {
      return true
    }
  }
  return false
}

export function setAllObjValuesFalse(obj: any) {
  for (let key in obj) {
    obj[key] = false
  }

  return obj
}

export function getCurrentDate() {
  const currentDate = new Date()

  const day = String(currentDate.getDate()).padStart(2, '0')
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
  const year = currentDate.getFullYear()

  return `${day}.${month}.${year}`
}

export function getFutureDate() {
  const currentDate = new Date()

  currentDate.setMonth(currentDate.getMonth() + 3)

  const day = currentDate.getDate()
  const year = currentDate.getFullYear()

  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ]
  const month = months[currentDate.getMonth()]

  return `${day} ${month} ${year} года`
}
